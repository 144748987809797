import { StrictMode } from 'react'
import { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client'
const App = lazy(() => import('./App.tsx'));
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Utilities/i18n';

if (import.meta.env.PROD) {
  console.log = () => { };
  console.warn = () => { };
}
createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Suspense>
      <App />
    </Suspense>
  </StrictMode>,
)
